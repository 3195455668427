


















































































































































import { Component, Vue } from "vue-property-decorator";
import {
  userPageListByRoleCode
} from "@/serve/healthFile/healthFile";
import {
  
  getItems,
  
  updateDiscount,
  updateItem,
  getRecommendTypeList,
} from "@/serve/medicalAppointment/itemsManage";
import { getDisease } from "@/serve/evaluatingBefore/evaluatingQuestion";
import { getTenantData } from "@/serve/medicalAppointment/institutionManage";
import Assignment from "./component/assignment.vue";
@Component({
  name: "DiscountManagement",
  components: { Assignment },
})
export default class DiscountManagement extends Vue {
  public tenantName = "";
  
  
  public formData = {
    name: "",
    discount: "",
    discountPrice: "",
    isRecommend: "",
    checkItemPrice: "",
    selectMedical: "",
    selectMedicalId: "",
    id: Number,
  };
  public itemData = [];

  public diseaseData = [];
  public itemDialog = false;
  public labelPosition = "left";
  public recommendTypeList = [];
  public isAdmin = sessionStorage.getItem("isAdmin");
  public restaurants = [];
  public created() {
    this.userPageListByRoleCodeApi()
    

    
    // this.getRecommendTypeListApi();
  }
  public mounted() {
    // this.getTenantData();
  }
  // -------------------------------------------------
  public isAssignment = true
  public current = 1
  public size = 10
  public roleCode = "ROLE_MANAGE_DOCTOR" 
  public userRoleList = []
  public form = {
    phone: "",
    sex:"",
    name: "",
  };
  
  public total = 0
  public doctorUserId = ''

  //编辑
  public editRow(list: any): void {
    this.isAssignment = !this.isAssignment;
    console.log("list",list);
    this.doctorUserId = list.user_id
    
    // this.formData.name = list[0].checkItemName;
    // this.formData.discount = list[0].discount;
    // this.formData.discountPrice = list[0].discountedPrice;
    // this.formData.isRecommend = list[0].isRecommend;
    // this.formData.checkItemPrice = list[0].checkItemPrice;
    // this.formData.selectMedicalId = list[0].categoryId;
    // this.formData.id = list[0].id;
  }
  //点击每页显示的条数触发获取数据
  public handleSizeChange(val: any): void {
    this.size = val;
    this.userPageListByRoleCodeApi();
  }
  //点击第N页触发获取数据
  public handleCurrentChange(val: any): void {
    this.current = val;
    this.userPageListByRoleCodeApi();
  }
  // 搜索
  public async search() {
    console.log("搜索",this.form);
    this.current = 1
    this.size = 10
    this.userPageListByRoleCodeApi()
    
  }
  // 重置功能
  public resetForm() {
    this.form.phone = ""
    this.form.name = ""
    this.form.sex = ""
    this.current = 1
    this.size = 10
    this.userPageListByRoleCodeApi()
    
  }
  public back(){
    this.isAssignment = !this.isAssignment;
  }
  
  // api相关
  // 管理医生列表
  public async userPageListByRoleCodeApi(){
    const params: any = {
      current:this.current,
      size:this.size,
      roleCode:this.roleCode,
      checkerName:this.form.name,
      checkerPhone:this.form.phone,
      checkerSex:this.form.sex,
    }
    const res = await userPageListByRoleCode(params);
    this.userRoleList = res.data.records
    this.total = res.data.total
    console.log("管理医生列表",res);
    
  }
  



  // ---------------------------------------------------------------
  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
 
  public inputChange(value: any) {
    this.formData.discountPrice = String(
      (Number(this.formData.checkItemPrice) * value).toFixed(2)
    );
  }

  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
 
  // 获取推荐分类
  public async getRecommendTypeListApi() {
    const res = await getRecommendTypeList();
    this.recommendTypeList = res.data;
  }
  // 改变体检报告的值发生的方法
  public changeContent(e: any) {
    //  this.formData.selectMedical = e.recommendType
    this.formData.selectMedicalId = e.id;
  }

  public async sirchUpdata(e: any) {
    const { id, isRecommend } = e;
    const data: any = {
      id,
      isRecommend,
    };
    const res = await updateItem(data);
    if (res.code === 200) {
      this.$message({
        message: "修改成功！",
        type: "success",
      });
    } else {
      this.$message.error(res.msg);
    }
  }
  
  

  
  //提交按钮
  public async handlerSubmit() {
    const { id, discount, selectMedicalId } = this.formData;
    const data: any = {
      discount,
      id: id,
      categoryId: selectMedicalId,
    };
    const res = await updateDiscount(data);
    this.itemDialog = false;
    if (res.success) {
      this.$message({
        message: "修改成功",
        type: "success",
      });
      
    } else {
      this.$message.error(res.msg);
    }
  }
  public editUser() {
    console.log("----");
    this.$router.push("/user");
  }
  
  /** 清空机构名称事件 */
  public clearName() {
    // this.form.tenantId = "";
  }
}
